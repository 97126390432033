import _ from 'lodash';

import React, { useEffect, useState } from 'react'
import * as XLSX from "xlsx";
import Luckysheet from "luckysheet";
import LoadHTMLFromExcel from '../Email/LoadEmailFromExcel';

import PDFViewer from '../PDFViewer/PDFViewer';
import {
  Card, Grid, IconButton, Tab, Tabs, CircularProgress
} from '@mui/material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import GridOnIcon from '@mui/icons-material/GridOn';
import DownloadIcon from '@mui/icons-material/Download';
import { getAttatchmentsData } from '../../../../../APICalls';
import { STORAGE_KEYS } from '../../../../../utils/utils';
import ArticleIcon from '@mui/icons-material/Article';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AttatchmentView = ({ POPDF = [], excelSheets = {}, transactionIdValue, excelAttatchments = [], txtAttatchments = [], companyName, PONumber }) => {
  const [attatchmentValue, setAttatchmentValue] = useState(0);
  const [excelSheetValue, setExcelSheetValue] = useState(0);
  const [selectedExcel, setSelectedExcel] = useState(null);
  const [selectedExcelOld, setSelectedExcelOld] = useState(null);
  const [loadingAttatchment, setLoadingAttatchment] = useState(false);
  const [isTxtView, setIsTxtView] = useState(false);
  const [isExcelView, setIsExcelView] = useState(false);
  const [isPDFView, setIsPDFView] = useState(false);
  const [firstExcelView, setFirstExcelView] = useState(0);

  useEffect(() => {
    const luckysheetContainer = document.getElementById("luckysheet");
    if (!luckysheetContainer) {
      return;
    }

    if (selectedExcel) {
      luckysheetContainer.innerHTML = ""; // Reset Luckysheet

      Luckysheet.create({
        container: "luckysheet",
        showtoolbar: true,
        showinfobar: false,
        allowEdit: true,
        data: selectedExcel.map((sheet) => ({
          ...sheet,
          config: {
            ...sheet.config,
            celldata: sheet.data.flatMap((row, r) =>
              row.map((cell, c) => ({
                r,
                c,
                v: { ...cell, locked: 1, tb: 2 }, // Lock each cell
              }))
            ),
          },
        })),
        sheetFormulaBar: false,
        showContextmenu: false,
        showAddRow: false,
        showAddCol: false,
        enableAddRow: false,
        enableAddCol: false,
        allowInsertRow: false,
        allowDeleteRow: false,
        allowInsertColumn: false,
        allowDeleteColumn: false,
        allowInsertCell: false,
        allowDeleteCell: false,
        allowAddRow: false,
        allowAddColumn: false,
      });

    }
  }, [selectedExcel, firstExcelView]);


  const parseExcel = (arrayBuffer) => {
    const workbook = XLSX.read(arrayBuffer, { type: "array", cellStyles: true });

    return workbook.SheetNames.map((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });

      let colWidths = {};
      let rowHeights = {};
      let cellConfig = {};

      const MAX_COLUMN_WIDTH = 250;
      const MIN_COLUMN_WIDTH = 50;
      const LINE_HEIGHT = 25;

      const formattedData = jsonData.map((row, rowIndex) =>
        row.map((cell, colIndex) => {
          const cellValue = cell || "";

          const estimatedWidth = cellValue.toString().length * 7;
          colWidths[colIndex] = Math.min(
            Math.max(colWidths[colIndex] || MIN_COLUMN_WIDTH, estimatedWidth),
            MAX_COLUMN_WIDTH
          );

          // Force row height increase for text wrap
          const numLines = Math.ceil(estimatedWidth / MAX_COLUMN_WIDTH);
          rowHeights[rowIndex] = Math.max(rowHeights[rowIndex] || LINE_HEIGHT, numLines * LINE_HEIGHT);

          // Correctly applying text wrap and left alignment
          cellConfig[`${rowIndex}_${colIndex}`] = {
            v: cellValue,
            vt: 0,
            ht: 0,  // Left align text
            tb: 2,  // **Force text wrapping**
            mc: null // **Ensure merge is not overriding wrapping**
          };

          return { v: cellValue };
        })
      );

      return {
        name: sheetName,
        data: formattedData,
        config: {
          columnlen: colWidths,
          rowlen: rowHeights,
          cell: cellConfig,
        },
      };
    });
  };

  const handleCallExcelOld = async (excelSheetValue = 0) => {
    setLoadingAttatchment(true);
    const { excelFilePath, data } = excelSheets[attatchmentValue]
    const sheetData = data['sheets'];

    const htmlValue = await getAttatchmentsData(localStorage.getItem(STORAGE_KEYS.TOKEN), transactionIdValue, 'COMPLETED', excelFilePath, true, sheetData[excelSheetValue], false, true)
    setSelectedExcelOld(htmlValue)
    setLoadingAttatchment(false);
  }

  const handleCallExcel = async (excelSheetValue = 0) => {
    setLoadingAttatchment(true);

    if (isExcelView && excelSheets[attatchmentValue]) {
      if (excelSheets[attatchmentValue]) {
        const { excelFilePath, data } = excelSheets[attatchmentValue];
        const sheetData = data.sheets || '';

        const htmlValue = await getAttatchmentsData(
          localStorage.getItem(STORAGE_KEYS.TOKEN),
          transactionIdValue,
          'COMPLETED',
          excelFilePath,
          true,
          sheetData[excelSheetValue]
        );

        const parsedData = parseExcel(htmlValue);
        setSelectedExcel(parsedData);
      }
    }

    setLoadingAttatchment(false);
  };

  const handleChangeIsFirstExcel = (event, newValue) => {
    setFirstExcelView(newValue)
  }

  const handleChangeExcelSheetValue = (event, newValue) => {
    setExcelSheetValue(newValue)
    handleCallExcel(newValue);
  }

  const handleChangeExcelSheetValueOld = (event, newValue) => {
    setExcelSheetValue(newValue)
    handleCallExcelOld(newValue);
  }

  const attatchmentValidationExcel = () => {
    return !_.isEmpty(POPDF)
      ? attatchmentValue - (POPDF.length + excelSheets[attatchmentValue]['data']['sheets'].length)
      : attatchmentValue
  }

  const showValidationComponent = (isExcel) => {
    if (isExcel) {
      return !_.isEmpty(POPDF)
        ? attatchmentValue >= POPDF.length
        : true
    } else {
      return !_.isEmpty(POPDF)
        ? attatchmentValue < POPDF.length
        : false
    }
  }

  const downloadPOPDF = () => {
    // create "a" HTML element with href to file & click
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([POPDF[attatchmentValue]], {
      type: "text/plain"
    }));
    const currentDateValue = new Date();
    // Extract date and time components
    const year = currentDateValue.getFullYear();
    const month = (currentDateValue.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = currentDateValue.getDate().toString().padStart(2, '0');
    const hours = currentDateValue.getHours().toString().padStart(2, '0');
    const minutes = currentDateValue.getMinutes().toString().padStart(2, '0');
    const seconds = currentDateValue.getSeconds().toString().padStart(2, '0');

    // Create formatted date and time strings
    const formattedDateTime = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

    // file extension, PDF
    a.setAttribute("download", `PDF_FILE_${formattedDateTime}.pdf`);
    document.body.appendChild(a);
    a.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(a);
  }

  const donwloadExcel = () => {
    const href = URL.createObjectURL(new Blob([selectedExcel]));
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    // file extension, XLS for excel
    link.setAttribute('download', 'AttatchmentExcel.xls');
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const handleChangeAttachmentValue = (event, newValue) => {
    setIsTxtView(false);
    setIsExcelView(false);
    setIsPDFView(false);

    // Get the selected tab's label directly
    const selectedLabel = event.target.closest("button")?.innerText || "";
    if (selectedLabel.includes("EXCEL")) {
      setIsExcelView(true);
      setAttatchmentValue(newValue);
      setFirstExcelView(true);
    } else if (selectedLabel.includes("TEXT")) {
      setIsTxtView(true);
      setAttatchmentValue(newValue);
    } else {
      setIsPDFView(true);
      setAttatchmentValue(newValue);
    }
  };

  const downloadAttatchment = () => {
    if (isPDFView && !_.isEmpty(POPDF)) {
      return (
        <>
          <IconButton
            onClick={downloadPOPDF}
            edge="end"
            color="primary"
          >
            <DownloadOutlinedIcon />
          </IconButton>
        </>
      )
    };
    if (isExcelView && !_.isEmpty(excelSheets)) {
      return (
        <>
          <IconButton
            onClick={donwloadExcel}
            edge="end"
            color="primary"
          >
            <DownloadIcon />
          </IconButton>
        </>
      )
    }
  }

  const checkAttatchmentComponent = () => {
    if (isTxtView && !_.isEmpty(txtAttatchments)) {
      return (
        <>
          <pre>{txtAttatchments[0]}</pre>
        </>
      )
    }
    if (!_.isEmpty(POPDF) && isPDFView) {
      return (
        <>
          <PDFViewer
            rowFile={POPDF[attatchmentValue - excelAttatchments.length]}
            isInput
          />
        </>
      )
    };
    if (isExcelView && !_.isEmpty(excelSheets) && !firstExcelView) {
      return (
        <>
          {selectedExcel && (
            <div id="luckysheet" style={{ width: "100%", height: "500px" }}></div>
          )}
        </>
      )
    } else if (isExcelView && !_.isEmpty(excelSheets) && firstExcelView) {
      return (<LoadHTMLFromExcel
        rowFile={selectedExcelOld}
      />
      )
    }
  }

  useEffect(() => {
    if (excelSheets && !_.isEmpty(excelSheets)) {
      handleCallExcel()
    }
    if (excelAttatchments && excelAttatchments.length === 0) {
      if (POPDF && POPDF.length === 0) {
        if (txtAttatchments && txtAttatchments.length === 0) {
        } else {
          setIsTxtView(true);
        }
      } else {
        setIsPDFView(true);
      }
    } else {
      setIsExcelView(true);
    }
  }, []);

  useEffect(() => {
    if (isExcelView) {
      handleCallExcel(0);
    }
  }, [attatchmentValue, isExcelView]);

  useEffect(() => {
    if (firstExcelView === 1) {
      handleCallExcelOld()
    }
  }, [firstExcelView])

  return (
    <div>
      <Grid
        container
      >
        <Grid item xs={12} sm={12} md={12}>
          <Tabs value={attatchmentValue} onChange={handleChangeAttachmentValue} variant="scrollable" scrollButtons>
            {!_.isEmpty(excelAttatchments) &&
              excelAttatchments.map((element, key) => (
                <Tab
                  key={`excel-${key}`}
                  icon={<GridOnIcon />}
                  iconPosition="start"
                  label={<span>{`Excel # ${key + 1}`} {downloadAttatchment()}</span>}
                  {...a11yProps(key)}
                />
              ))
            }
            {!_.isEmpty(POPDF) &&
              POPDF.map((element, key) => (
                <Tab
                  key={`pdf-${key}`}
                  icon={<PictureAsPdfIcon />}
                  iconPosition="start"
                  label={<span>{`PDF # ${key + 1}`} {downloadAttatchment()}</span>}
                  {...a11yProps(excelAttatchments.length + key)}
                />
              ))
            }
            {!_.isEmpty(txtAttatchments) &&
              txtAttatchments.map((element, key) => (
                <Tab
                  key={`text-${key}`}
                  icon={<ArticleIcon />}
                  iconPosition="start"
                  label={<span>{`Text # ${key + 1}`} {downloadAttatchment()}</span>}
                  {...a11yProps(POPDF.length + excelAttatchments.length + key)}
                />
              ))
            }
          </Tabs>
          {(!_.isEmpty(excelSheets) && isExcelView) && (
            <Tabs
              value={firstExcelView}
              onChange={handleChangeIsFirstExcel}
              variant="scrollable"
              scrollButtons
            >
              <Tab
                icon={<GridOnIcon />}
                iconPosition="start"
                label={
                  <span>
                    First View
                  </span>
                }
              />
              <Tab
                icon={<GridOnIcon />}
                iconPosition="start"
                label={
                  <span>
                    Second View
                  </span>
                }

              />
            </Tabs>
          )}
          {(!_.isEmpty(excelSheets) && isExcelView && firstExcelView) && (
            <Tabs
              value={excelSheetValue}
              onChange={handleChangeExcelSheetValueOld}
              variant="scrollable"
              scrollButtons
            >
              {excelSheets[attatchmentValue]['data']['sheets'].map((element, key) => {
                return (
                  <Tab
                    icon={<GridOnIcon />}
                    iconPosition="start"
                    label={
                      <span>
                        {element}
                        {downloadAttatchment()}
                      </span>
                    }
                    {...a11yProps(1)}
                  />
                )
              }
              )}
            </Tabs>
          )}
          <Card
            style={{ height: '800px', overflow: 'auto' }}
          >
            {loadingAttatchment
              ? <Grid
                container
                justifyContent="center"
                paddingTop="3%"
              >
                <Grid item xs={12}>
                  <CircularProgress color="info" />
                </Grid>
                <Grid item xs={12}>
                  <h2>
                    Loading data, please wait...
                  </h2>
                </Grid>
              </Grid>
              : checkAttatchmentComponent()}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default AttatchmentView