import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

import React, { Suspense, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import TagManager from 'react-gtm-module';

import { STORAGE_KEYS } from "./helpers/helpers";

import './App.css';

import LoadingPage from "./loading/LoadingPage";
import AppContext from './AppContext';
import MainStepperTemplate from "./Homepage/Home/StepperComponents/MainStepperTemplate";
import Home from "./Homepage/Home/SOPHome/Settings/SettingsHome";
import InactivityModal from "./InactivityModal";


import { setHeartbeatInactivity } from "./APICalls";
import InnerComponent from "./Homepage/InnerComponent";

const HomeComponent = React.lazy(() => import('./Homepage/InnerComponent'));
const InvitationPage = React.lazy(() => import('./Homepage/InvitePage/InvitePage'));
const HomeSOP = React.lazy(() => import('./Homepage/Home/SOPHome/Home'))
const MainComponentRender = React.lazy(() => import('./Homepage/Home/SOPHome/DetailsPage/MainComponentHOCRendering'))
const MainComponentRenderTrial = React.lazy(() => import('./Homepage/Home/HomeComponents/TrialComponents/MainComponentTrial'));
const LandingMIRASOP = React.lazy(() => import('./Homepage/middleRoute/MiddleRoutePage'))
const StatsPage = React.lazy(() => import('./Homepage/Home/SOPHome/Status/CurrentStatusPage'))
const TrialHome = React.lazy(() => import('./Homepage/Home/HomeComponents/TrialComponents/HomeList'));
const BlogsDetails = React.lazy(() => import('./Homepage/Home/Blogs/BlogDetails'));
const OrderTracking = React.lazy(() => import("./Homepage/Home/SOPHome/OrderTracking/OrderTrackingView"));
const MaterialFinder = React.lazy(() => import("./Homepage/Home/SOPHome/MaterialFinder/MaterialFinderView"));
const MassAccuracyHome = React.lazy(() => import("./Homepage/MassAccuracyTracking/MassAccuracyTrackingHome"));
const APILayerDocMassAccuracy = React.lazy(() => import("./Homepage/MassAccuracyTracking/APILayer/APILayerComponent"));
const APILayerOrderTracking = React.lazy(() => import("./Homepage/Home/SOPHome/OrderTracking/APILayer/APILayerComponent"));
const HomeInvoiceManagement = React.lazy(() => import('./Homepage/Home/HomeComponents/InvoiceManagement/InvoiceManagementDashboard'));
const DetailsInvoices = React.lazy(() => import('./Homepage/Home/HomeComponents/InvoiceManagement/DetailsPageInvoice/MainComponentInvoice'));
const SpecificEventPage = React.lazy(() => import("./Homepage/SpecificEventHome"));
const AIExtractorHome = React.lazy(() => import("./Homepage/AIExtractor/AIExtractorHome"));
const DetailsAIExtractor = React.lazy(() => import("./Homepage/AIExtractor/DetailsAIExtractor/MainComponentNA"));
const AuditComponentHomeExtractor = React.lazy(() => import("./Homepage/AIExtractor/Settings/SettingsHome"));
const InvoiceManagementSettings = React.lazy(() => import("./Homepage/Home/HomeComponents/InvoiceManagementProduct/Settings/SettingsHome"));
const SettingsOrderTracking = React.lazy(() => import("./Homepage/Home/SOPHome/OrderTracking/Settings/SettingsHome"));

const PrivateRoute = ({ children }) => {
  const auth = localStorage.getItem(STORAGE_KEYS.TOKEN);
  const [token, setToken] = useState(null);
  const [event, setEvent] = useState('Event'); // Event type, initially 'Event'
  const [count, setCount] = useState(0); // Number of user interactions
  const [elapsed, setElapsed] = useState(1800000); // Time since last interaction (seconds)
  const [currentStatus, setCurrentStatus] = useState("active"); // User activity state
  const [activeHeartbeat, setActiveHeartbeat] = useState(600); // Active timer (seconds)
  const [openInactivityModal, setOpenInactivityModal] = useState(false);

  const handleCloseInactivityModal = () => {
    setOpenInactivityModal(false);
  }

  const onAction = (event) => {
    setEvent(event?.type ?? 'Event'); // Update event type, default to 'Event'
    setCount(count + 1); // Increment interaction count
    setElapsed(1800000); // Reset elapsed time upon interaction
  };

  const onIdle = () => {
    setCurrentStatus('idle'); // Update state when user becomes idle
  };

  const onActive = () => {
    setCurrentStatus('active'); // Update state when user becomes active
  };

  const checkingCaseCall = async () => {
    await setHeartbeatInactivity(token)
    // Reset active timer upon reaching 0
    setActiveHeartbeat(600);
  }

  const { getRemainingTime } = useIdleTimer({
    onAction,
    onActive,
    onIdle,
    timeout: 1800000_00, // Timeout duration (seconds)
    throttle: 1, // Event handling delay (seconds)
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsed(Math.ceil(getRemainingTime() / 1000)); // Update elapsed time
    }, 500); // Update every 500 milliseconds

    return () => clearInterval(interval); // Clear interval on unmount
  }, [getRemainingTime]); // Recalculate elapsed time on timeout/throttle changes

  useEffect(() => {
    if (currentStatus === 'active') {
      const heartbeatInterval = setInterval(() => {
        setActiveHeartbeat(activeHeartbeat - 1); // Decrement active timer
        if (activeHeartbeat <= 0) {
          // Handle active timer reaching 0 (e.g., reset, trigger actions)
          checkingCaseCall();
        }
      }, 1000); // Update every second

      return () => clearInterval(heartbeatInterval); // Clear interval on state change
    }
  }, [currentStatus, activeHeartbeat]); // Re-run on state changes

  useEffect(() => {
    setToken(localStorage.getItem(STORAGE_KEYS.TOKEN))
    TagManager.initialize({ gtmId: 'GTM-MV5BCWQH' });
  }, [])

  return auth ? (
    <>
      <InactivityModal
        openModal={currentStatus !== "active"}
        handleCloseModal={handleCloseInactivityModal}
        token={token}
      />
      {children}
    </>
  ) : (
    <Navigate to="/home" replace />
  );
};

const redirectUnknownRoutes = () => {
  return <Navigate to="/home" replace />;
}

function App() {
  const [userInfo, setUserInfo] = useState({ token: "", username: "", auth: false });

  const refreshContext = () => {
    setUserInfo({
      token: localStorage.getItem(STORAGE_KEYS.TOKEN),
    });
  };

  window.addEventListener("storage", function () {
    refreshContext();
  }, false);

  useEffect(() => {
    refreshContext();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <AppContext.Provider value={{ username: userInfo.username || "", auth: userInfo.auth === "true", token: userInfo.token || "" }}>
          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route
                path="/home"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/demos-page"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/demos-contact"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/docs"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/product/procurement-software"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/product/vendor-invoice-management"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/product/sales-order-automation"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/product/intelligent-document-processing"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/contactUs"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/webinar"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/order-management-system-demo"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/middlePage"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/webinar/success"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/consultation/:id"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/sales-order-software"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/sales-order-software/success"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/order-processing-software"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/order-processing-software/success"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/sales-order-automation"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/sales-order-automation/success"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/order-management-platform"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/order-management-platform/success"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/contactUs/success"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/whyVAO"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/careers"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/privacy"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/signUpPage"
                element={(
                  <InnerComponent />
                )}
              />
              <Route
                path="/company"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/trialHome"
                element={(
                  <TrialHome />
                )}
              />
              <Route
                path="/detailsTrial/:id/:page/:status"
                element={(
                  <MainComponentRenderTrial />
                )}
              />
              <Route
                path="/blogs/:title"
                element={(
                  <BlogsDetails />
                )}
              />
              <Route
                path="/login"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/details/:id/:page/:status"
                element={(
                  <PrivateRoute>
                    <MainComponentRender />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/invoice/details/:id/:page/:status"
                element={(
                  <PrivateRoute>
                    <DetailsInvoices />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/ai-extractor/details/:id/:page/:status"
                element={(
                  <PrivateRoute>
                    <DetailsAIExtractor />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/stats"
                element={(
                  <PrivateRoute>
                    <StatsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/orderTracking"
                element={(
                  <OrderTracking />
                )}
              />
              <Route
                path="/orderTracking/:id"
                element={(
                  <PrivateRoute>
                    <OrderTracking />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/orderTracking/:id/:tokenSOP"
                element={(
                  <OrderTracking />
                )}
              />
              <Route
                path="/materialFinder"
                element={(
                  <PrivateRoute>
                    <MaterialFinder />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/order-tracking-api-layer"
                element={(
                  <APILayerOrderTracking />
                )}
              />
              <Route
                path="/mass-accuracy-tracking-api-layer"
                element={(
                  <APILayerDocMassAccuracy />
                )}
              />
              <Route
                path="/register/:id"
                element={(
                  <MainStepperTemplate />
                )}
              />
              <Route
                path="/faq"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/blogs"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/events"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/demos-page/sales-order-automation"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/demos-page/procurement-status-tracking"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/demos-page/vendor-invoice-management"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/event-details/:id"
                element={(
                  <SpecificEventPage />
                )}
              />
              <Route
                path="/SOPHome"
                element={(
                  <PrivateRoute>
                    <HomeSOP />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/AI-Extractor-Dashboard"
                element={(
                  <PrivateRoute>
                    <AIExtractorHome />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/invoiceManagement"
                element={(
                  <PrivateRoute>
                    <HomeInvoiceManagement />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/pricing"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/hiw"
                element={(
                  <HomeComponent />
                )}
              />
              <Route
                path="/acceptInvitation"
                element={(
                  <InvitationPage />
                )}
              />
              <Route
                path="/mass-accuracy-tracking"
                element={(
                  <PrivateRoute>
                    <MassAccuracyHome />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/settingsInvoiceManagement"
                element={(
                  <PrivateRoute>
                    <InvoiceManagementSettings />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/settingsOrderTracking"
                element={(
                  <PrivateRoute>
                    <SettingsOrderTracking />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/audit-ai-extractor"
                element={(
                  <PrivateRoute>
                    <AuditComponentHomeExtractor />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path="/"
                element={
                  <Navigate to={"/home"} />
                }
              />
              <Route
                path="/middleDashboard"
                element={(
                  <PrivateRoute>
                    <LandingMIRASOP />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/sopSettings"
                element={(
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                )}
              />
              <Route path="*" element={redirectUnknownRoutes()} />
            </Routes>
          </Suspense>
        </AppContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
