import axios from 'axios'
import { API_URLS } from './helpers/helpers';

const SUCCESS = "SUCCESS";
const ERROR = "ERROR";

export const createUser = async (email, name, password, companyName, reason) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_USER}`, {
        body: JSON.stringify({
            email,
            password,
            name,
            "company_name": companyName,
            "onboard_reason": reason
        }),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const createUserFirstStep = async (email, password, firstName, lastName, companyName, city, state, country, zip) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_USER_FIRST_STEP}`, {
        body: JSON.stringify({
            email,
            password,
            "first_name": firstName,
            "last_name": lastName,
            "company_name": companyName,
            city,
            state,
            country,
            "zip_code": zip
        }),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}


export const loginAPI = async (username, password) => {
    const returnCall = await fetch(API_URLS.FREETIER_LOGIN, {
        body: new URLSearchParams({
            'grant_type': '',
            'username': username,
            'password': password,
            'scope': '',
            'client_id': '',
            'client_secret': ''
        }),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*"
        },
        method: "POST"
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const recoverPassword = async (username) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_RECOVER_PASSWORD}`, {
        body: JSON.stringify({
            username,
        }),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const logoutAPI = async (token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_LOGOUT}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getLogoAPI = async (token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_LOGO_APP}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.status === 200 ? response.blob() : null)
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const setOrganizationAPI = async (token, organization) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_ORGANIZATION}?${new URLSearchParams({
        'client_name': organization,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const inviteMembersAPI = async (token, username, email) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_INVITE_MEMBERS}?${new URLSearchParams({
        'inviter_name': username,
        'invitee_email': email
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getUserClientInfoAPI = async (token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_USER_CLIENT_INFO}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const acceptInvitationAPI = async (clientId, invitationId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_ACCEPT_INVITATION_API}?${new URLSearchParams({
        'client_id': clientId,
        'invitation_id': invitationId
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getDataBySelection = async (token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_APP_SERVICE_GET_ALL_FILES}`, {
        method: 'GET',
        headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "upgrade-insecure-requests": 1,
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else if (response.status === 204) {
                return {
                    status: SUCCESS,
                    payload: {
                        records: []
                    }
                };
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const getDataByMatch = async (matchType, jobId, page, pageSize, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_MATCH_RESULT}?${new URLSearchParams({
        'page_size': pageSize,
        'match_type': matchType,
        'job_id': jobId,
        'sort_field': '-modifiedDate',
        'page': page

    })}`, {
        method: 'GET',
        headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "upgrade-insecure-requests": 1,
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else if (response.status === 204) {
                return {
                    status: SUCCESS,
                    payload: {
                        records: []
                    }
                };
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const getGuestMatches = async (specId, jobId, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_GUEST_MATCHES}?${new URLSearchParams({
        'job_id': jobId,
        'guest_spec_id': specId
    })}`, {
        method: 'GET',
        headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "upgrade-insecure-requests": 1,
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else if (response.status === 204) {
                return {
                    status: SUCCESS,
                    payload: {
                        records: []
                    }
                };
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const uploadCSVAPI = async (processId, token) => {
    const formdata = new FormData();
    formdata.append("file", document.querySelector('input[name="file"]').files[0]);
    const returnCall = await fetch(`${API_URLS.FREETIER_UPLOAD_FILE}?${new URLSearchParams({
        'process_id': processId,
        'status': 'SUBMITTED',
    })}`, {
        body: formdata,
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "upgrade-insecure-requests": 1,
            'Authorization': `Bearer ${token}`,

        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}


export const read_CSV_first_records = async (token) => {
    const formdata = new FormData();
    formdata.append("file", document.querySelector('input[name="file"]').files[0]);
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_CSV_FIRST_RECORDS}?${new URLSearchParams({
        'records_length': 10,
    })}`, {
        body: formdata,
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "upgrade-insecure-requests": 1,
            'Authorization': `Bearer ${token}`,

        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}


export const uploadCSVColumnsSettings = async (processId, configData, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UPDATE_DATA_CONFIG}`, {
        body: JSON.stringify({
            'process_id': processId,
            'config': configData,
        }),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "upgrade-insecure-requests": 1,
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}


export const getJobsAPI = async (page, pageSize = 10, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_JOBS_DATA}?${new URLSearchParams({
        'page': page,
        'page_size': pageSize,
        'sort_field': '-createdDate'
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "upgrade-insecure-requests": 1,
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else if (response.status === 401) {
                const data = response.json()
                    .then((arrayResponse) => {
                        return { status: "UNAUTHORIZED" }
                    });
                return data;
            }
            else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const getDataStoreAPI = async (page, pageSize = 10, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_DATA_STORAGE_LIST}?${new URLSearchParams({
        'page': page,
        'page_size': pageSize,
        'sort_field': '-modifiedDate'
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "upgrade-insecure-requests": 1,
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else if (response.status === 401) {
                const data = response.json()
                    .then((arrayResponse) => {
                        return { status: "UNAUTHORIZED" }
                    });
                return data;
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}


export const downloadCSVApi = async (processId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_CSV_API}?${new URLSearchParams({
        'transaction_id': processId,
        'index': index,
        'type_format': 'CSV'
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const getUserConsumptionAPI = async (token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_DAILY_CONSUMPTION}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else if (response.status === 401) {
                const data = response.json()
                    .then((arrayResponse) => {
                        return { status: "UNAUTHORIZED" }
                    });
                return data;
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const preprocessAPICall = async (token, processId) => {
    const payload = {
        conf: {
            process_id: processId,
            token: token
        }
    };
    const authHeader = 'Basic ' + btoa('vao-airflow:0Cg0!@c8D8Un');

    const returnCall = await axios.post(API_URLS.FREETIER_PREPROCESS_CALL, payload, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: authHeader
        }
    })
        .then(response => {
            if (response.status === 200) {
                return { status: SUCCESS, payload: response.data }
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const getDataDropdowns = async (text, token, limit = 1000) => {
    const conditionalURL = text
        ? `${API_URLS.FREETIER_GET_DATA_DROPDOWNS}?${new URLSearchParams({
            'text': text,
            'limit': limit,
        })}`
        : `${API_URLS.FREETIER_GET_DATA_DROPDOWNS}?${new URLSearchParams({
            'limit': limit,
        })}`;

    const returnCall = await fetch(conditionalURL, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: SUCCESS, payload: ArrayResponse }
                    });
                return data;
            }
            else if (response.status === 401) {
                const data = response.json()
                    .then((arrayResponse) => {
                        return { status: "UNAUTHORIZED" }
                    });
                return data;
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const createJobAPI = async (jobObject, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_JOB}`, {
        body: JSON.stringify(jobObject),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const etlMatchingAPICall = async (token, jobId) => {
    const payload = {
        conf: {
            job_id: jobId,
            token: token
        }
    };
    const authHeader = 'Basic ' + btoa('vao-airflow:0Cg0!@c8D8Un');

    const returnCall = await axios.post(API_URLS.FREETIER_ETL_MATCHING_CALL, payload, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: authHeader
        }
    })
        .then(response => {
            if (response.status === 200) {
                return { status: SUCCESS, payload: response.data }
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const getDataProcessById = async (token, processId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_DATA_BY_ID}?${new URLSearchParams({
        'process_id': processId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: SUCCESS, payload: ArrayResponse }
                    });
                return data;
            }
            else if (response.status === 401) {
                const data = response.json()
                    .then((arrayResponse) => {
                        return { status: "UNAUTHORIZED" }
                    });
                return data;
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const getMatchJobById = async (token, jobId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_JOB_BY_ID}?${new URLSearchParams({
        'job_id': jobId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: SUCCESS, payload: ArrayResponse }
                    });
                return data;
            }
            else if (response.status === 401) {
                const data = response.json()
                    .then((arrayResponse) => {
                        return { status: "UNAUTHORIZED" }
                    });
                return data;
            } else {
                return { status: ERROR };
            }
        })
    return returnCall;
}

export const uploadCSV = async (csvFile, question, token) => {
    const formdata = new FormData();
    formdata.append("file", csvFile);
    const returnCall = await axios.post(API_URLS.CONVERSATIONAL_AI_PROCESS_QUESTION,
        formdata,
        {
            params: {
                question: question,
            },
            headers:
            {
                'Accept': 'application/json',
                'Content-Disposition': "attachment; filename=template.csv",
                'Content-Type': 'multipart/form-data',
                'token': `${token}`,
            }
        })
        .then(element => {
            return element.data;
        })
        .catch(err => console.error(err));
    return returnCall;
}

export const verifyEmail = async (email) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_VERIFY_EMAIL}?${new URLSearchParams({
        'email': email,
    })}`, {

        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const transactionsFilters = async (
    token, processStatus = "ALL", businessUnit = "ALL", customerEmail = "ALL",
    poNumber = "ALL", startDate = "ALL", endDate = "ALL", companyName = "ALL",
    status = "COMPLETED", soNumber = "ALL", soStatus = "ALL", soFailureReason = "ALL", hasAttatchment = "ALL",
    mailbox = "ALL", orderType = "ALL", shipToParty = "ALL", soldToParty = "ALL", material = "ALL", salesOrganization = "ALL",
    approvedBy = "ALL", page, size, sortingField, massApproval = "ALL", signal
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SOP_GET_SOP_TRANSACTIONS}?${new URLSearchParams({
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': status,
        'process_status': processStatus,
        'business_unit': businessUnit,
        'customer_email': customerEmail,
        'customer_name': customerEmail,
        'po_number': poNumber,
        "start_order_date": startDate,
        "end_order_date": endDate,
        'company_name': companyName,
        'so_number': soNumber,
        'so_status': soStatus,
        'so_failure_reason': soFailureReason,
        'order_type': orderType,
        'page': page,
        'size': size,
        'sort_field': sortingField,
        'has_attachment': hasAttatchment,
        'mass_approval': massApproval,
        "sales_org": salesOrganization,
        "sold_to_party": soldToParty,
        "material": material,
        "ship_to_party": shipToParty,
        "approved_by": approvedBy
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            mailbox
        }),
        signal
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error, status }
            }
        })
    return returnCall;
}

export const getPOJSON = async (token, transactionId, statusValue) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SOP_GET_PO_JSON}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': statusValue,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getEmailAPI = async (token, transactionId, statusValue) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_HTML_BODY_EMAIL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': 'EE-01',
        'module_name': 'EMAIL-EXTRACT',
        'module_version': '1.0.0',
        'status': statusValue,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getCompanyData = async (token, transactionId, statusValue) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_COMPANY_DATA}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': statusValue,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json()
                .then(data => {
                    return ({ status: response.status, data })
                }
                )
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getEmailAditionalDataAPI = async (token, transactionId, statusValue) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_EMAIL_ADDITIONAL_DATA}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': 'EE-01',
        'module_name': 'EMAIL-EXTRACT',
        'module_version': '1.0.0',
        'status': statusValue,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getDropdownValues = async (token) => {
    const returnCall = await fetch(API_URLS.FREETIER_GET_DROPDOWN_VALUES, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const uploadOrder = async (csvFile, workflowId, token) => {
    const formdata = new FormData();
    formdata.append("file", csvFile);
    const returnCall = await axios.post(`${API_URLS.FREETIER_UPLOAD_EML_FILE}?${new URLSearchParams({
        'workflow_id': workflowId
    })}`,
        formdata,
        {
            headers:
            {
                'Accept': 'application/json',
                'Content-Disposition': "attachment; filename=template.csv",
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(element => {
            return element.data;
        })
        .catch(err => console.error(err));
    return returnCall;
}

export const approveByTidIndex = async (transactionId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_APPROVE_TID_INDEX}?${new URLSearchParams({
        'transaction_id': transactionId,
        'index': index,
        'approve': true
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    /*await fetch(`${API_URLS.SSSP_GENERATE_HISTORY}?${new URLSearchParams({
        'transaction_id': transactionId
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })*/
    return returnCall;
}

export const approveByTidIndexTrial = async (transactionId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_APPROVE_TID_INDEX_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'index': index,
        'approve': true,
        'fake_user_id': token,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    /*await fetch(`${API_URLS.SSSP_GENERATE_HISTORY}?${new URLSearchParams({
        'transaction_id': transactionId
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })*/
    return returnCall;
}

export const editCurrentJSON = async (transactionId, JSONData, modifiedRows, currentIndex, token, isMassApproval = false) => {
    const returnCall = await fetch(API_URLS.FREETIER_UPDATE_PO_JSON_BY_INDEX, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'rows': modifiedRows,
            'final_json': JSONData,
            'transaction_id': transactionId,
            'index': currentIndex,
            'module_id': "FETCH-01",
            'module_name': "FETCH-DATA",
            'module_version': '1.0.0',
            'is_mass_approval': isMassApproval
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const editCurrentJSONTrial = async (transactionId, JSONData, modifiedRows, currentIndex, token, isMassApproval = false) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UPDATE_PO_JSON_BY_INDEX_TRIAL}?${new URLSearchParams({
        'fake_user_id': token,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'rows': modifiedRows,
            'final_json': JSONData,
            'transaction_id': transactionId,
            'index': currentIndex,
            'module_id': "FETCH-01",
            'module_name': "FETCH-DATA",
            'module_version': '1.0.0',
            'is_mass_approval': isMassApproval
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}


export const updateJSON = async (token, JSONData, transactionId) => {
    const returnCall = await fetch(API_URLS.FREETIER_UPDATE_PO_JSON_BY_INDEX, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'final_json': JSONData,
            'transaction_id': transactionId,
            'module_id': "FETCH-01",
            'module_name': "FETCH-DATA",
            'module_version': '1.0.0'
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const updateJSONTrial = async (token, JSONData, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UPDATE_PO_JSON_BY_INDEX_TRIAL}?${new URLSearchParams({
        'fake_user_id': token,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'final_json': JSONData,
            'transaction_id': transactionId,
            'module_id': "FETCH-01",
            'module_name': "FETCH-DATA",
            'module_version': '1.0.0'
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const unapproveByTidIndex = async (transactionId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UNDO_APPROVE_TID}?${new URLSearchParams({
        'transaction_id': transactionId,
        'index': index,
        'undo_approve': true
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const unapproveByTidIndexTrial = async (transactionId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UNDO_APPROVE_TID_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'index': index,
        'undo_approve': true,
        'fake_user_id': token,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const setNotPO = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SET_NOT_PO}${transactionId}&not_po=true`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const undoNotPoByTid = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UNDO_NOT_PO_TID}?${new URLSearchParams({
        'transaction_id': transactionId,
        'undo_not_po': true
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const validateTIDNotPO = async (transactionId, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_VALIDATE_NOT_PO}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const createSOPConfigurationAPI = async (token, extractionConfiguration, classificationConfiguration, metadata) => {
    const returnCall = await fetch(API_URLS.FREETIER_CREATE_SOP_CONFIG, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'extraction_configuration': extractionConfiguration,
            'classification_configuration': classificationConfiguration,
            metadata,
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const updateSOPConfigurationAPI = async (token, configId, extractionConfiguration, classificationConfiguration, metadata) => {
    const returnCall = await fetch(API_URLS.FREETIER_UPDATE_SOP_CONFIG, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'id': configId,
            'extraction_configuration': extractionConfiguration,
            'classification_configuration': classificationConfiguration,
            metadata,
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getSOPConfigurations = async (token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_SOP_CONFIG}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const massiveApprovalAPI = async (token, transactions) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_MASSIVE_APPROVAL_API}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            transactions: transactions,
        }),
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR" };
            }
        })
    return returnCall;
}

export const massiveApprovalAPITrial = async (token, transactions) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_MASSIVE_APPROVAL_API_TRIAL}?${new URLSearchParams({
        'fake_user_id': token,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            transactions: transactions,
        }),
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR" };
            }
        })
    return returnCall;
}

export const auditsFilters = async (
    token, transactionId = "ALL", workflowId = "ALL", userId = "ALL",
    username = "ALL", clientId = "ALL", action = "ALL", prevValue = "ALL", currentValue = "ALL", start_order_date = "ALL", end_order_date = "ALL", mailbox = "ALL", page, size
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_AUDITS_FILTERS_URL}?${new URLSearchParams({
        'transactionId': transactionId,
        'workflowId': workflowId,
        'userid': userId,
        'username': username,
        'clientId': clientId,
        'action': action,
        'prevValue': prevValue,
        'currentValue': currentValue,
        'start_order_date': start_order_date,
        'end_order_date': end_order_date,
        'mailboxes': mailbox,
        'page': page,
        'size': size
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )

        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const downloadAuditReport = async (startDate, endDate, mailbox, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GENERATE_REPORT}?${new URLSearchParams({
        'start_date': startDate,
        'end_date': endDate,
        'mailbox': mailbox
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    }).then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}


export const getRecordsList = async (token, page = 1, size = 20, shiptoaddress = "ALL", shiptoparty = "ALL", soldtoaddress = "ALL", soldtoparty = "ALL",
    materialdescription = "ALL", materialnumber = "ALL", salesorganization = "ALL", distributionchannel = "ALL", division = "ALL",
    salesdistrict = "ALL", salesoffice = "ALL", salesgroup = "ALL", paymentterms = "ALL", equipmenttype = "ALL", ordertype = "ALL", orderreason = "ALL",
    partnerfunction = "ALL", partnernumber = "ALL", shippingcondition = "ALL", suom = "ALL", plant = "ALL", countryofdeliveringplant = "ALL",
    incoterms = "ALL", incotermsdescription = "ALL", masterDataCollection, signal) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_LIST_RECORD}?${new URLSearchParams({
        "collection_name": masterDataCollection,
        page,
        size
    })}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ship_to_address: shiptoaddress,
            ship_to_party: shiptoparty,
            sold_to_party: soldtoparty,
            sales_organization: salesorganization,
            distribution_channel: distributionchannel,
            division,
            material_description: materialdescription,
            material_number: materialnumber,
            plant,
            country_of_delivering_plant: countryofdeliveringplant,
            incoterms,
            incoterms_description: materialdescription,
            soldtoaddress,
            suom,
            salesdistrict,
            salesoffice,
            salesgroup,
            paymentterms,
            ordertype,
            orderreason,
            partnerfunction,
            partnernumber,
            shippingcondition,
            incoterms_description: incotermsdescription,
        }),
        signal
    }).then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const createRecord = async (token, shiptoaddress = "ALL", shiptoparty = "ALL", soldtoaddress = "ALL", soldtoparty = "ALL",
    materialdescription = "ALL", materialnumber = "ALL", salesorganization = "ALL", distributionchannel = "ALL", division = "ALL",
    salesdistrict = "ALL", salesoffice = "ALL", salesgroup = "ALL", paymentterms = "ALL", equipmenttype = "ALL", ordertype = "ALL", orderreason = "ALL",
    partnerfunction = "ALL", partnernumber = "ALL", shippingcondition = "ALL", suom = "ALL", plant = "ALL", countryofdeliveringplant = "ALL",
    incoterms = "ALL", incotermsdescription = "ALL") => {
    const returnCall = await fetch(`${API_URLS.FREETIER_ADD_NEW_RECORD}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify([{
            shiptoaddress,
            shiptoparty,
            soldtoparty,
            salesorganization,
            distributionchannel,
            division,
            materialdescription,
            materialnumber,
            plant,
            countryofdeliveringplant,
            incoterms,
            materialdescription,
            soldtoaddress,
            suom,
            salesdistrict,
            salesoffice,
            salesgroup,
            paymentterms,
            ordertype,
            orderreason,
            partnerfunction,
            partnernumber,
            shippingcondition,
            incotermsdescription,
        }]),
    }).then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const deleteRecord = async (token, ids) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DELETE_RECORD}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "ids": ids }),
    }).then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const updateRecord = async (token, object_id, ship_to_address = "ALL", ship_to_party = "ALL", sold_to_party = "ALL",
    sales_organization = "ALL", distribution_channel = "ALL", division = "ALL", material_description = "ALL", material_number = "ALL", plant = "ALL", country_of_delivering_plant = "ALL",
    incoterms = "ALL", incoterms_description = "ALL", selectedMasterDataCollection, page = 1, size = 20) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UPDATE_RECORD}?${new URLSearchParams({
        object_id,
        'collection_name': selectedMasterDataCollection,
        ship_to_address,
        ship_to_party,
        sold_to_party,
        sales_organization,
        distribution_channel,
        division,
        material_description,
        material_number,
        plant,
        country_of_delivering_plant,
        incoterms,
        incoterms_description,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    }).then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const uploadCSVMasterData = async (csvFile, token, selectedMasterDataCollection) => {
    const formdata = new FormData();
    formdata.append("file", csvFile);
    formdata.append("collection_name", selectedMasterDataCollection)
    const returnCall = await axios.post(API_URLS.FREETIER_UPLOAD_CSV_RECORDS,
        formdata,
        {
            headers:
            {
                'Accept': 'application/json',
                'Content-Disposition': "attachment; filename=template.csv",
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(element => {
            return element.data;
        })
        .catch(err => {
            return { message: err.response.data.message }
        });
    return returnCall;
}

export const downloadCSVMasterData = async (token, collection_name, page = 1, size = 50) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DOWNLOAD_CSV_RECORDS}?${new URLSearchParams({
        collection_name,
        page,
        size
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

const excelAPICase = async (token, transactionId, status, path, sheet, isHTML) => {
    const returnCall = await fetch(`${API_URLS.FREEETIER_GET_ATTATCHMENTS_DATA}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'transaction_id': transactionId,
            'module_id': 'EE-01',
            'module_name': 'EMAIL-EXTRACT',
            'module_version': '1.0.0',
            'status': status,
            'file_path': path,
            'excel_as_html': isHTML,
            'sheet_name': sheet
        })
    })
        .then(res => {
            return res.arrayBuffer()
        })
        .then(element => {
            if (isHTML) {
                const decoder = new TextDecoder("utf-8")
                return decoder.decode(element);
            } else {
                return element
            }
        })
        .catch(err => {
            console.error(err);
            return { detail: err }
        });
    return returnCall;
}

const txtAPICase = async (token, transactionId, status, path, sheet) => {
    try {
        const response = await fetch(`${API_URLS.FREEETIER_GET_ATTATCHMENTS_DATA}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'transaction_id': transactionId,
                'module_id': 'EE-01',
                'module_name': 'EMAIL-EXTRACT',
                'module_version': '1.0.0',
                'status': status,
                'file_path': path,
                'excel_as_html': false,
                'sheet_name': sheet
            })
        });

        // Ensure successful response
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.text(); // Wait for text response
        return data;
    } catch (error) {
        console.error("Error fetching file:", error);
        return { detail: error.message };
    }
}

export const getExcelSheets = async (token, transactionId, path) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_SHEETS_NAME}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'transaction_id': transactionId,
            'module_id': 'EE-01',
            'module_name': 'EMAIL-EXTRACT',
            'module_version': '1.0.0',
            'status': 'COMPLETED',
            'file_path': path,
            'excel_as_html': true,
            'sheet_name': ""
        })
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}


export const excelAPICaseInvoiceReport = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_INVOICE_REPORT}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    })
        .then(res => {
            return res.arrayBuffer()
        })
        .then(element => {
            const decoder = new TextDecoder("utf-8")
            return decoder.decode(element);
        })

        .catch(err => {
            console.error(err);
            return { detail: err }
        });
    return returnCall;
}

const PDFAPICase = async (token, transactionId, status, path) => {
    const returnCall = await fetch(`${API_URLS.FREEETIER_GET_ATTATCHMENTS_DATA}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'transaction_id': transactionId,
            'module_id': 'EE-01',
            'module_name': 'EMAIL-EXTRACT',
            'module_version': '1.0.0',
            'status': status,
            'file_path': path
        })
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}
export const getAttatchmentsData = async (token, transactionId, status, path, isExcel, sheet = "", isTxt = false, isHTML = false) => {
    if (isTxt) {
        return await txtAPICase(token, transactionId, status, path)
    } else {
        if (isExcel) {
            return await excelAPICase(token, transactionId, status, path, sheet, isHTML)
        } else {
            return await PDFAPICase(token, transactionId, status, path)
        }
    }
}

export const reloadPOWithCore = async (shipTo, Material, shipToNumber, materialNumber, salesOrganization, companyName, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_REFRESH_API_CALL}?${new URLSearchParams({
        'ship_to_address': shipTo,
        'material_description': Material,
        'ship_to_number': shipToNumber,
        'material_number': materialNumber,
        'salesorganization': salesOrganization,
        'company_name': companyName,
        'token': token,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const saveUserLocation = async (token, windowId, add_location = '', remove_location = '') => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SAVE_USER_LOCATION}`, {
        body: JSON.stringify({
            add_location,
            remove_location,
            window_id: windowId
        }),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getUserLocation = async (token, transactionId, sessionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_USER_LOCATION}?${new URLSearchParams({
        'transaction_id': transactionId,
        'window_id': sessionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const takeLocationControl = async (token, windowId, path, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_TAKE_LOCATION_CONTROL}`, {
        body: JSON.stringify({
            path,
            transaction_id: transactionId,
            window_id: windowId
        }),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const createNewWorkflow = async (token, structure) => {
    const returnCall = await fetch(API_URLS.FREETIER_CREATE_WORKFLOW_URL, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(structure)
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getOrdersStatus = async (startDate, endDate, company, orderType, exportData, mailbox, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_ORDERS_STATUS_PIECHART}?${new URLSearchParams({
        'start_date': startDate,
        'end_date': endDate,
        'company_name': company,
        'order_type': orderType,
        'is_export': exportData
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            mailbox
        }),
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getOrdersStatusTrial = async (startDate, endDate, company, orderType, exportData, mailbox, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_ORDERS_STATUS_PIECHART_TRIAL}?${new URLSearchParams({
        'start_date': startDate,
        'end_date': endDate,
        'company_name': company,
        'order_type': orderType,
        'is_export': exportData,
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            mailbox
        }),
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const transactionsFilterTrial = async (
    token, processStatus = "ALL", businessUnit = "ALL", customerEmail = "ALL",
    poNumber = "ALL", startDate = "ALL", endDate = "ALL", companyName = "ALL",
    status = "COMPLETED", soNumber = "ALL", soStatus = "ALL", soFailureReason = "ALL", hasAttatchment = "ALL",
    mailbox = "ALL", orderType = "ALL",
    page, size, sortingField, signal
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_TRIAL_GET_LIST_HOME}?${new URLSearchParams({
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': status,
        'process_status': processStatus,
        'business_unit': businessUnit,
        'customer_email': customerEmail,
        'customer_name': customerEmail,
        'po_number': poNumber,
        "start_order_date": startDate,
        "end_order_date": endDate,
        'company_name': companyName,
        'so_number': soNumber,
        'so_status': soStatus,
        'so_failure_reason': soFailureReason,
        'order_type': orderType,
        'page': page,
        'size': size,
        'sort_field': sortingField,
        'has_attachment': hasAttatchment,
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            mailbox
        }),
        signal
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error, status }
            }
        })
    return returnCall;
}

export const uploadOrderTrial = async (csvFile, token) => {
    const formdata = new FormData();
    formdata.append("file", csvFile);
    const returnCall = await axios.post(`${API_URLS.FREETIER_UPLOAD_EML_FILE_TRIAL}?${new URLSearchParams({
        'fake_user_id': token
    })}`,
        formdata,
        {
            headers:
            {
                'Accept': 'application/json',
                'Content-Disposition': "attachment; filename=template.csv",
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(element => {
            return element.data;
        })
        .catch(err => console.error(err));
    return returnCall;
}

export const getPOJSONTrial = async (token, transactionId, statusValue) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SOP_GET_PO_JSON_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': statusValue,
        'fake_user_id': token,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getEmailAPITrial = async (token, transactionId, statusValue) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_HTML_BODY_EMAIL_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': 'EE-01',
        'module_name': 'EMAIL-EXTRACT',
        'module_version': '1.0.0',
        'status': statusValue,
        'fake_user_id': token
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getCompanyDataTrial = async (token, transactionId, statusValue) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_COMPANY_DATA_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': statusValue,
        'fake_user_id': token,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json()
                .then(data => {
                    return ({ status: response.status, data })
                }
                )
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getEmailAditionalDataAPITrial = async (token, transactionId, statusValue) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_EMAIL_ADDITIONAL_DATA_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': 'EE-01',
        'module_name': 'EMAIL-EXTRACT',
        'module_version': '1.0.0',
        'status': statusValue,
        'fake_user_id': token,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

const excelAPICaseTrial = async (token, transactionId, status, path) => {
    const returnCall = await fetch(`${API_URLS.FREEETIER_GET_ATTATCHMENTS_DATA_TRIAL}?${new URLSearchParams({
        'fake_user_id': token,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'transaction_id': transactionId,
            'module_id': 'EE-01',
            'module_name': 'EMAIL-EXTRACT',
            'module_version': '1.0.0',
            'status': status,
            'file_path': path,
            'excel_as_html': true,
        })
    })
        .then(res => {
            return res.arrayBuffer()
        })
        .then(element => {
            const decoder = new TextDecoder("utf-8")
            return decoder.decode(element);
        })

        .catch(err => {
            console.error(err);
            return { detail: err }
        });
    return returnCall;
}

const PDFAPICaseTrial = async (token, transactionId, status, path) => {
    const returnCall = await fetch(`${API_URLS.FREEETIER_GET_ATTATCHMENTS_DATA_TRIAL}?${new URLSearchParams({
        'fake_user_id': token,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'transaction_id': transactionId,
            'module_id': 'EE-01',
            'module_name': 'EMAIL-EXTRACT',
            'module_version': '1.0.0',
            'status': status,
            'file_path': path,
        })
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const getAttatchmentsDataTrial = async (token, transactionId, status, path, isExcel) => {
    return isExcel
        ? await excelAPICaseTrial(token, transactionId, status, path)
        : await PDFAPICaseTrial(token, transactionId, status, path)
}

export const uploadMasterDataTrial = async (csvFile, token) => {
    const formdata = new FormData();
    formdata.append("file", csvFile);
    const returnCall = await axios.post(`${API_URLS.FREETIER_UPLOAD_MASTER_DATA_FILE_TRIAL}?${new URLSearchParams({
        'fake_user_id': token
    })}`,
        formdata,
        {
            headers:
            {
                'Accept': 'application/json',
                'Content-Disposition': "attachment; filename=template.csv",
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(element => {
            return element.data;
        })
        .catch(err => console.error(err));
    return returnCall;
}

export const orderUploadStatusTrial = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_ORDER_UPLOAD_STATUS_TRIAL}?${new URLSearchParams({
        'fake_user_id': token,
        'transaction_id': transactionId,
        'mark_as_temporal': true
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((responseData) => {
            return responseData.json();
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const orderUploadStatus = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_ORDER_UPLOAD_STATUS}?${new URLSearchParams({
        'transaction_id': transactionId
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((responseData) => {
            return responseData.json();
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const downloadCSVApiTRIAL = async (processId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_CSV_API_TRIAL}?${new URLSearchParams({
        'transaction_id': processId,
        'index': index,
        'type_format': 'CSV',
        'fake_user_id': token,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const downloadConfirmationPDFTrial = async (processId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DOWNLOAD_CONFIRMATION_PDF_TRIAL}?${new URLSearchParams({
        'transaction_id': processId,
        'index': index,
        'fake_user_id': token,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const downloadConfirmationPDF = async (processId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DOWNLOAD_CONFIRMATION_PDF}?${new URLSearchParams({
        'transaction_id': processId,
        'index': index,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const getNotifyToTrial = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_NOTIFY_TO_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'fake_user_id': token
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getNotifyTo = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_NOTIFY_TO}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const updateNotifyTo = async (transactionId, token, notifyTo) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UPDATE_NOTIFY_TO}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        body: JSON.stringify(notifyTo),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const updateNotifyToTrial = async (transactionId, token, notifyTo) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UPDATE_NOTIFY_TO_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'fake_user_id': token,
    })}`, {
        body: JSON.stringify(notifyTo),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const reloadPOWithCoreZORTrial = async (shipTo, Material, shipToNumber, materialNumber, salesOrganization, token) => {
    const returnCall = await fetch(`${API_URLS.SSSP_REFRESH_ZOR_CASES_TRIAL}?${new URLSearchParams({
        'ship_to_address': shipTo,
        'material_description': Material,
        'ship_to_number': shipToNumber,
        'material_number': materialNumber,
        'salesorganization': salesOrganization,
        'token': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getBlogs = async (page, keyword, size = 4) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_BLOGS}?${new URLSearchParams({
        'page': page,
        'size': size,
        'keyword': keyword
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const createBlogsAPI = async (token, title, description, file, timeToRead, author, seoDescription) => {
    const formdata = new FormData();
    formdata.append("title", title)
    formdata.append("body", description)
    formdata.append("file", file);
    formdata.append("read_time", timeToRead)
    formdata.append("writer_name", author)
    formdata.append("seo_description", seoDescription)
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_BLOGS}`, {
        method: 'POST',
        body: formdata,
        headers: {
            "Accept": "application/json",
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const deleteBlogsAPI = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DELETE_BLOGS}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getBlogInfoAPI = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_BLOG_BY_ID}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const updateAPICallData = async (token, transactionId, changeApplied, currDescription, uploadedImagePrev, timeToRead, author) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_EDIT_BLOG_BY_ID}?${new URLSearchParams({
        'transaction_id': transactionId,
        'title': changeApplied,
        'mainImageLink': uploadedImagePrev,
        "time": timeToRead,
        "writer_name": author
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            description: currDescription
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getAllMailboxes = () => {

}

export const auditsFiltersTrial = async (
    token, transactionId = "ALL", workflowId = "ALL", userId = "ALL",
    username = "ALL", clientId = "ALL", action = "ALL", prevValue = "ALL", currentValue = "ALL", start_order_date = "ALL", end_order_date = "ALL", mailbox = ["ALL"], page, size
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_AUDITS_TRIAL_PAGE}?${new URLSearchParams({
        'transactionId': transactionId,
        'workflowId': workflowId,
        'userid': userId,
        'username': username,
        'clientId': clientId,
        'action': action,
        'prevValue': prevValue,
        'currentValue': currentValue,
        'start_order_date': start_order_date,
        'end_order_date': end_order_date,
        'page': page,
        'size': size,
        'fake_user_id': token,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )

        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const createContactTrial = async (
    token, name, email, company, message, pathname
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_CONTACT_TRIAL}?${new URLSearchParams({
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            email,
            company,
            message,
            'is_demo': true,
            'location': pathname
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const undoNotPoByTidTrial = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UNDO_NOT_PO_TID_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'fake_user_id': token,
        'undo_not_po': true
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const validateTIDNotPOTrial = async (transactionId, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_VALIDATE_NOT_PO_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'fake_user_id': token,
        'undo_not_po': true
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const setNotPOTrial = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SET_NOT_PO_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'fake_user_id': token,
        'not_po': true
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const searchByPONumber = async (token, PONumber) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SEARCH_BY_PO_NUMBER}?${new URLSearchParams({
        'po_number': PONumber,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR", payload: {} };
            }
        })
    return returnCall;
}

export const trackOrderAPI = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_TRACK_ORDER_API}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 204) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR", payload: {} };
            }
        })
    return returnCall;
}


export const getOrdersSOStatus = async (startDate, endDate, company, orderType, exportData, mailbox, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SO_STATUS_PIECHART}?${new URLSearchParams({
        'start_date': startDate,
        'end_date': endDate,
        'company_name': company,
        'order_type': orderType,
        'is_export': exportData
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            mailbox
        }),
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const dataCollectCall = async (
    token, name, email, company, jobTitle, pathname
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_CONTACT_TRIAL}?${new URLSearchParams({
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            email,
            company,
            'job': jobTitle,
            'location': pathname,
            'is_demo': false
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getPreFilledData = async (transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_PREFILLED_DATA}?${new URLSearchParams({
        'transaction_id': transactionId,
        'fake_user_id': transactionId
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR", payload: {} };
            }
        })
    return returnCall;
}

export const trackOrderAPITrial = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_TRACK_ORDER_API_TRIAL}?${new URLSearchParams({
        'transaction_id': transactionId,
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR", payload: {} };
            }
        })
    return returnCall;
}

export const searchByPONumberTrial = async (token, PONumber) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SEARCH_BY_PO_NUMBER_TRIAL}?${new URLSearchParams({
        'po_number': PONumber,
        'fake_user_id': token
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR", payload: {} };
            }
        })
    return returnCall;
}

export const createContactPage = async (
    token, name, email, company, phoneNumber, jobTitle, industry, companySize, pathname
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_CONTACT_TRIAL}?${new URLSearchParams({
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            email,
            company,
            'phone_number': phoneNumber,
            'is_demo': true,
            'job_title': jobTitle,
            industry,
            'company_size': companySize,
            'location': pathname
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const setHeartbeatInactivity = async (
    token
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_HEARTBEAT_VALIDATION}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getDataWelcomeMessage = async (
    token
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_WELCOME_DATA}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getPOJSONPools = async (token, transactionId, statusValue) => {
    const returnCall = await fetch(`${API_URLS.SSSP_GET_POOLS_DATA}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': statusValue,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const editCurrentJSONPools = async (transactionId, JSONData, modifiedRows, currentIndex, token, isMassApproval = false) => {
    const returnCall = await fetch(API_URLS.FREETIER_UPDATE_PO_JSON_POOLS_BY_INDEX, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'rows': modifiedRows,
            'final_json': JSONData,
            'transaction_id': transactionId,
            'index': currentIndex,
            'module_id': "FETCH-01",
            'module_name': "FETCH-DATA",
            'module_version': '1.0.0',
            'is_mass_approval': isMassApproval
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const createContactPageWithDocument = async (
    token, firstName, lastName, email, companyName, phoneNumber, jobTitle, industry, companySize, pathname
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_CONTACT_TRIAL_DOCUMENT}?${new URLSearchParams({
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'first_name': firstName,
            'last_name': lastName,
            email,
            'company': companyName,
            'phone_number': phoneNumber,
            'job_title': jobTitle,
            industry,
            'company_size': companySize,
            'location': pathname
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const createWebinarContact = async (
    token, firstName, lastName, email, companyName, phoneNumber, jobTitle, industry, companySize, pathname
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_WEBINAR_CONTACT}?${new URLSearchParams({
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'first_name': firstName,
            'last_name': lastName,
            email,
            'company': companyName,
            'phone_number': phoneNumber,
            'job_title': jobTitle,
            industry,
            'company_size': companySize,
            'location': pathname
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const materialFinderSearch = async (token, materialText) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SEARCH_MATERIAL}?${new URLSearchParams({
        'text': materialText,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const materialFinderGetSuggestions = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_MATERIAL_SUGGESTIONS}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const createOrganization = async (
    token, name
) => {
    const returnCall = await fetch(API_URLS.FREETIER_CREATE_ORGANIZATION_NEW, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'name': name,
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getOrganizations = async (
    token
) => {
    const returnCall = await fetch(API_URLS.FREETIER_GET_ORGANIZATION_NEW, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getWorkflowsPerUser = async (
    token
) => {
    const returnCall = await fetch(API_URLS.FREETIER_GET_WORKFLOWS_PER_USER, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const createWorkflow = async (
    token, bodyStructure
) => {
    const returnCall = await fetch(API_URLS.FREETIER_CREATE_WORKFLOW, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(bodyStructure)
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const updateWorkflow = async (
    token, bodyStructure
) => {
    const returnCall = await fetch(API_URLS.FREETIER_WORKFLOW_UPDATE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(bodyStructure)
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const updateOrganization = async (
    token, name, file, id
) => {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append("name", name);
    formdata.append("file", file);

    const returnCall = await fetch(API_URLS.FREETIER_UPDATE_ORGANIZATION_NEW, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: formdata,
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const changeWorkflowStatus = async (token, workflowId, status) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_CHANGE_WORKFLOW_STATUS}?${new URLSearchParams({
        'workflow_id': workflowId,
        'status': status,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR", payload: {} };
            }
        })
    return returnCall;
}

export const getUsersByClient = async (
    token, permission, page = 1, size = 10
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_USERS_BY_CLIENT}?${new URLSearchParams({
        'page': page,
        'size': size
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getUsersByClientAndPermission = async (
    token, page = 1, size = 10, permission
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_USERS_BY_CLIENT_BY_PERMISSION}?${new URLSearchParams({
        permission,
        'page': page,
        'size': size
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}



export const assignNewRole = async (
    token, userId, Role
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CHANGE_USER_ROLE}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            user_id: userId,
            role: Role
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const inviteUsers = async (
    token, username, permissions
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_INVITE_USER_ADMIN}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            username: username,
            permissions: permissions
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .catch(error => {
            return error
        })
    return returnCall;
}

export const searchCountry = async (
    countryText
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SEARCH_COUNTRY}?${new URLSearchParams({
        'text': countryText,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .catch(error => {
            return error
        })
    return returnCall;
}

export const searchState = async (
    countryId, stateText
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SEARCH_STATE}?${new URLSearchParams({
        'text': stateText,
        'country_id': countryId
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .catch(error => {
            return error
        })
    return returnCall;
}

export const searchCity = async (
    countryId, stateId, cityText,
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SEARCH_CITY}?${new URLSearchParams({
        'text': cityText,
        'country_id': countryId,
        'state_id': stateId
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .catch(error => {
            return error
        })
    return returnCall;
}

export const getMassApprovalHistory = async (token, page = 1, size = 10) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_MASS_APPROVAL_HISTORY}?${new URLSearchParams({
        'page': page,
        'size': size
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .catch(error => {
            return { status: 'error', data: [] }
        })
    return returnCall;
}

export const updateUserPermits = async (token, userId, permits) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UPDATE_USER_PERMITS_URL}`, {
        body: JSON.stringify({
            'permissions': permits,
            'user_id': userId,
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        method: "POST"
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getMailboxesUsersList = async (token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_USERS_ALL_MAILBOXES}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .catch(error => {
            return { status: 'error', data: [] }
        })
    return returnCall;
}

export const updateUserMailboxes = async (token, userId, mailboxes) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UPDATE_USER_MAILBOXES}`, {
        body: JSON.stringify({
            'mailboxes': mailboxes,
            'user_id': userId,
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        method: "POST"
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}
export const refreshPORegion = async (token, shipTo, Material, shipToNumber, materialNumber, salesOrganization, companyName, transactionId, cnpj) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_REFRESH_API_REGION_CALL}`, {
        body: JSON.stringify({
            "ship_to_address": shipTo,
            "material_description": Material,
            "cnpj": cnpj,
            "ship_to_number": shipToNumber,
            "material_number": materialNumber,
            "salesorganization": salesOrganization,
            "company_name": companyName,
            "transaction_id": transactionId
        }),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const convertPORegion = async (token, suom, quantity, uom, materialNumber, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CONVERT_API_REGION_CALL}`, {
        body: JSON.stringify({
            "suom": suom,
            "quantity": quantity,
            "uom": uom,
            "material_number": materialNumber,
            "transaction_id": transactionId
        }),
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getMailboxOfOrder = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_WORKFLOW_BY_TRANSACTIONID}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .catch(error => {
            return { status: 'error', data: [] }
        })
    return returnCall;
}


export const getChangelogData = async (token, page = 0, size = 10) => {
    const returnCall = await fetch(`${API_URLS.FREE_TIER_GET_CHANGELOG_DATA}?${new URLSearchParams({
        'page': page,
        'size': size
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getChangelogFiles = async (token, s3Path) => {
    const returnCall = await fetch(`${API_URLS.FREE_TIER_GET_CHANGELOG_FILES}?${new URLSearchParams({
        's3_url': s3Path
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const createRecordChangelog = async (token, currImage, prevImage, changeApplied, currentDesc, previousDesc, launchDate) => {
    const formdata = new FormData();
    formdata.append("prev_version_img", prevImage);
    formdata.append("curr_version_img", currImage);
    const returnCall = await fetch(`${API_URLS.FREE_TIER_UPLOAD_CHANGELOG}?${new URLSearchParams({
        'change_applied': changeApplied,
        'prev_version_desc': previousDesc,
        'curr_version_desc': currentDesc,
        'launch_date': launchDate
    })}`, {
        body: formdata,
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "upgrade-insecure-requests": 1,
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR" };
            }
        })
    return returnCall;
}

export const changeNotificationCheckedAPI = async (token, value = true) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CHANGE_NOTIFICATION_CHECKED}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'check_notify_status': value
        }),
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getMasterDataLists = async (token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_MASTERDATA_LIST}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getMassAccuracyByText = async (text, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_MASS_ACCURACY_BY_TEXT}?${new URLSearchParams({
        'text': text
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getListDataTracking = async (id, token, page = 1, size = 10) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_MASS_ACCURACY_LIST_DATA}?${new URLSearchParams({
        page,
        size
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'id': id
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const updateSteps = async (id, steps, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UPDATE_CURRENT_STEPS_MASS_ACCURACY}?${new URLSearchParams({
        'transaction_id': id,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'steps': steps
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getCSVReportVao = async (
    token, processStatus = "ALL", businessUnit = "ALL", customerEmail = "ALL",
    poNumber = "ALL", startDate = "ALL", endDate = "ALL", companyName = "ALL",
    status = "COMPLETED", soNumber = "ALL", soStatus = "ALL", soFailureReason = "ALL", hasAttatchment = "ALL",
    mailbox = "ALL", orderType = "ALL", salesOrganization = "ALL", shipToParty = "ALL", soldToParty = "ALL", material = "ALL",
    massApproval = "ALL", notPoReason = "ALL", sortingField = '-inputs.date_obj', signal = undefined
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DOWNLOAD_CSV_REPORT_VAO}?${new URLSearchParams({
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': status,
        'process_status': processStatus,
        'business_unit': businessUnit,
        'customer_email': customerEmail,
        'customer_name': customerEmail,
        'po_number': poNumber,
        "start_order_date": startDate,
        "end_order_date": endDate,
        'company_name': companyName,
        'so_number': soNumber,
        'so_status': soStatus,
        'so_failure_reason': soFailureReason,
        'order_type': orderType,
        'sales_org': salesOrganization,
        'sold_to_party': soldToParty,
        'ship_to_party': shipToParty,
        'material': material,
        'sort_field': sortingField,
        'has_attachment': hasAttatchment,
        'mass_approval': massApproval,
        'not_po_reason': notPoReason
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            mailbox
        }),
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const getCSVAuditReportVAO = async (
    token, processStatus = "ALL", businessUnit = "ALL", customerEmail = "ALL",
    poNumber = "ALL", startDate = "ALL", endDate = "ALL", companyName = "ALL",
    status = "COMPLETED", soNumber = "ALL", soStatus = "ALL", soFailureReason = "ALL", hasAttatchment = "ALL",
    mailbox = "ALL", orderType = "ALL", salesOrganization = "ALL", shipToParty = "ALL", soldToParty = "ALL", material = "ALL",
    massApproval = "ALL", notPoReason = "ALL", sortingField = '-inputs.date_obj', signal = undefined
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DOWNLOAD_CSV_AUDIT_REPORT_VAO}?${new URLSearchParams({
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': status,
        'process_status': processStatus,
        'business_unit': businessUnit,
        'customer_email': customerEmail,
        'customer_name': customerEmail,
        'po_number': poNumber,
        "start_order_date": startDate,
        "end_order_date": endDate,
        'company_name': companyName,
        'so_number': soNumber,
        'so_status': soStatus,
        'so_failure_reason': soFailureReason,
        'order_type': orderType,
        'sales_org': salesOrganization,
        'sold_to_party': soldToParty,
        'ship_to_party': shipToParty,
        'material': material,
        'sort_field': sortingField,
        'has_attachment': hasAttatchment,
        'mass_approval': massApproval,
        'not_po_reason': notPoReason
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            mailbox
        }),
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const getCSVReport = async (
    token, processStatus = "ALL", businessUnit = "ALL", customerEmail = "ALL",
    poNumber = "ALL", startDate = "ALL", endDate = "ALL", companyName = "ALL",
    status = "COMPLETED", soNumber = "ALL", soStatus = "ALL", soFailureReason = "ALL", hasAttatchment = "ALL",
    mailbox = "ALL", orderType = "ALL", shipToParty = "ALL", soldToParty = "ALL", material = "ALL", salesOrganization = "ALL",
    approvedBy = "ALL", sortingField, massApproval = "ALL", signal
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DOWNLOAD_CSV_REPORT}?${new URLSearchParams({
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': status,
        'process_status': processStatus,
        'business_unit': businessUnit,
        'customer_email': customerEmail,
        'customer_name': customerEmail,
        'po_number': poNumber,
        "start_order_date": startDate,
        "end_order_date": endDate,
        'company_name': companyName,
        'so_number': soNumber,
        'so_status': soStatus,
        'so_failure_reason': soFailureReason,
        'order_type': orderType,
        'sales_org': salesOrganization,
        'sold_to_party': soldToParty,
        'ship_to_party': shipToParty,
        'material': material,
        'sort_field': sortingField,
        'has_attachment': hasAttatchment,
        'mass_approval': massApproval,
        // 'not_po_reason': notPoReason
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            mailbox
        }),
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getCSVMassAccuracyTemplate = async (
    token, page = 1, size = 10
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DOWNLOAD_CSV_MASS_ACCURACY}?${new URLSearchParams({
        page,
        size,

    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "id": [],
            "sold_to_party": "",
            "ship_to_party": "",
            "status": "",
            "assignee": "",
            "sprint": ""
        }),
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const uploadCSVAPIMassAccuracy = async (csvFile, token) => {
    const formdata = new FormData();
    formdata.append("file", csvFile);
    const returnCall = await axios.post(API_URLS.FREETIER_UPLOAD_CSV_MASS_ACCURACY,
        formdata,
        {
            headers:
            {
                'Accept': 'application/json',
                'Content-Disposition': "attachment; filename=template.csv",
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(element => {
            return { status: "SUCCESS" };
        })
        .catch(err => {
            return { status: "ERROR" }
        });
    return returnCall;
}

export const createNewRecordMassAccuracy = async (record, token) => {
    const { asignee, enddate, shiptoparty, soldtoparty, sprint, startdate, taskid } = record;
    const returnCall = await fetch(`${API_URLS.FREETIER_CREATE_RECORD_MASS_ACCURACY}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'task_id': taskid,
            'ship_to_party': shiptoparty,
            'sold_to_party': soldtoparty,
            'sprint': sprint,
            'start_date': startdate,
            'end_date': enddate,
            'assignee': asignee
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const editNewRecordMassAccuracy = async (record, id, token) => {
    const { asignee, enddate, shiptoparty, soldtoparty, sprint, startdate, taskid } = record;
    const returnCall = await fetch(`${API_URLS.FREETIER_EDIT_RECORD_MASS_ACCURACY}?${new URLSearchParams({
        id,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'task_id': taskid,
            'ship_to_party': shiptoparty,
            'sold_to_party': soldtoparty,
            'sprint': sprint,
            'start_date': startdate,
            'end_date': enddate,
            'assignee': asignee
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const uploadCSVAPIOrderTracking = async (csvFile, token) => {
    const formdata = new FormData();
    formdata.append("file", csvFile);
    const returnCall = await axios.post(API_URLS.FREETIER_UPLOAD_ORDER_TRACKING,
        formdata,
        {
            headers:
            {
                'Accept': 'application/json',
                'Content-Disposition': "attachment; filename=template.csv",
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(element => {
            return { status: "SUCCESS" };
        })
        .catch(err => {
            return { status: "ERROR" }
        });
    return returnCall;
}

export const downloadCSVOrderTracking = async (
    token, id, page = 1, size = 10
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_DOWNLOAD_ORDER_TRACKING}?${new URLSearchParams({
        page,
        size,

    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "transaction_id": [id],
        }),
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const getEventsInfo = async (
    isOnDemand = 'false', page = 1, size = 10
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_EVENTS_LIST}?${new URLSearchParams({
        is_on_demand: isOnDemand,
        page,
        size,

    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const apiGeneratePDFMail = async (
    token, email, parsedNames, digitalRevenue, IncreasedRevenueEnhancedCustomer, operationalCostsEfficiency,
    pieCostSavings, pieNewRevenue, pieDigitalRevenue, totalIncrementalImpact, directSavings, costSavings,
    totalSaving, percentageTimeSaving, pieHeadcountCosts, pieOrderErrors, averageMinutesSingleOrder
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SEND_PDF_LOGIC}?${new URLSearchParams({
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'to_email': email,
            'first_name': parsedNames,
            "digital_revenue_impact": `${digitalRevenue.toLocaleString()} / Year`,
            "satisfaction_and_brand_loyalty": `${IncreasedRevenueEnhancedCustomer.toLocaleString()} / Year`,
            "operational_costs_efficiency": `${operationalCostsEfficiency.toLocaleString()} / Year`,
            "pie_cost_savings": pieCostSavings,
            "pie_new_revenue": pieNewRevenue,
            "pie_digital_revenue": pieDigitalRevenue,
            "pie_headcount_costs": pieHeadcountCosts,
            "pie_order_errors": pieOrderErrors,
            "bar_manually_order_entry": averageMinutesSingleOrder,
            "pie_vao_ai_automated_order_entry": 1,
            "total_incremental_financial_impact": `${totalIncrementalImpact.toLocaleString()}`,
            "direct_order_processing_savings": `${directSavings.toLocaleString()} / Year`,
            "cost_savings_due_to_order_errors": `${costSavings.toLocaleString()} / Year`,
            "total_savings": `${totalSaving.toLocaleString()}`,
            "time_saved_in_order_processing": `${percentageTimeSaving.toLocaleString()}%`
        })
    })
        .then(response => response.json()
            .then(data => ({ statusPDF: response.status, dataPDF: data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const sendChecklistDocument = async (
    token, email, parsedNames, phoneNumber, customer
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SEND_CHECKLIST_DOCUMENT}?${new URLSearchParams({
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'to_email': email,
            'first_name': parsedNames,
            "phone_number": phoneNumber,
            "company": customer
        })
    })
        .then(response => response.json()
            .then(data => ({ statusPDF: response.status, dataPDF: data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const sendChecklistDocumentProcurement = async (
    token, email, parsedNames, phoneNumber, customer
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SEND_CHECKLIST_DOCUMENT_PROCUREMENT}?${new URLSearchParams({
        'fake_user_id': token
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'to_email': email,
            'first_name': parsedNames,
            "phone_number": phoneNumber,
            "company": customer
        })
    })
        .then(response => response.json()
            .then(data => ({ statusPDF: response.status, dataPDF: data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const transactionsFiltersInvoice = async (
    token, poNumber = "ALL", supplier = "ALL", discrepancies = "ALL", orderDate = "ALL", signal
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_INVOICES_LIST}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'supplier': supplier,
            'po_number': poNumber,
            "order_date": orderDate,
            "discrepancies": discrepancies
        }),
        signal
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const sendSMSMessage = async (
    token, transactionId, soIndex = '0', username, phoneNumber
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_SMS_ORDER_TRACKING}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            "transaction_id": transactionId,
            "so_index": soIndex,
            "user_name": username,
            "phone_number": phoneNumber
        }
        )
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data: data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const sendPhoneCallMessage = async (
    token, transactionId, soIndex = '0', username, phoneNumber
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_PHONECALL_ORDER_TRACKING}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            "transaction_id": transactionId,
            "so_index": soIndex,
            "user_name": username,
            "phone_number": phoneNumber
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data: data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getSupplierInfoOrderTracking = async (
    token, supplier, page = 1, size = 10
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_TABLE_SUPPLIER_ORDER_TRACKING}?${new URLSearchParams({
        'supplier': supplier,
        'page': page,
        "size": size,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data: data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getInformationOrderByTid = async (
    token, parentTid
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_TID_PARENT_ORDER_TRACKING}?${new URLSearchParams({
        'parent_jobs_transaction_id': parentTid,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data: data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getTrackingShippingInfo = async (
    token, trackingNumber, transactionId
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_SHIPPING_TRACKING_DATA}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            "transaction_id": transactionId,
            "tracking_number": trackingNumber,
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data: data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}


export const getEventsInfoByTid = async (
    isOnDemand = 'True', transactionId, page = 1, size = 10
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_EVENTS_LIST_BY_TID}?${new URLSearchParams({
        is_on_demand: isOnDemand,
        transactionId, transactionId,
        page,
        size,

    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

const getAttatchmentFileInvoice = async (token, transactionId, path) => {
    const returnCall = await fetch(`${API_URLS.FREEETIER_GET_ATTATCHMENTS_DATA_INVOICE}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'transaction_id': transactionId,
            'file_path': path
        })
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}


export const getAttatchmentsDataInvoice = async (token, transactionId, status, path, isExcel) => {
    return await getAttatchmentFileInvoice(token, transactionId, path)
}


export const getInvoiceByTid = async (
    token, transactionId
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_TID_INVOICE_INFO}?${new URLSearchParams({
        'transaction_id': transactionId
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error }
            }
        })
    return returnCall;
}

export const getMetadataByTID = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.SSSP_GET_METADATA}?${new URLSearchParams({
        'transaction_id': transactionId,
        'module_id': "FETCH-01",
        'module_name': "FETCH-DATA",
        'module_version': "1.0.0",
        'status': 'COMPLETED',
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getPOPDFAttatchment = async (token, path) => {
    const returnCall = await fetch(`${API_URLS.FREEETIER_GET_ATTATCHMENT_HOMEPAGE}?${new URLSearchParams({
        'attachment_file': path
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

export const getCheckToken = async (
    token
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_CHECK_TOKEN_API_DATA}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "token": token,
        })
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data: data }))
        )
        .then((responseData) => {
            return responseData;
        })
    return returnCall;
}

export const getAdsDataOrderTracking = async (token) => {
    const returnCall = await fetch(`${API_URLS.SSSP_GET_ADS_ORDER_TRACKING}?${new URLSearchParams({
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getListAIExtractor = async (
    token,
    company_name = "ALL",
    date_doc_received_start = "ALL",
    date_doc_received_end = "ALL",
    sender_id = "ALL",
    region = "ALL",
    country = "ALL",
    status = "ALL",
    approved_by = "ALL",
    wrong_doc = "ALL",
    page, size, signal
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_LIST_AI_EXTRACTOR}?${new URLSearchParams({
        'page': page,
        'size': size,
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            company_name,
            date_doc_received_start,
            date_doc_received_end,
            sender_id,
            region,
            country,
            status,
            approved_by,
            wrong_doc,

        }),
        signal
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                return { detail: error, status: 'CANCELLED' }
            } else {
                return { detail: error, status }
            }
        })
    return returnCall;
}

export const getPOJSONAIExtractor = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_PO_JSON_AI_EXTRACTOR}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json()
                .then(data => ({ status: response.status, data }))
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

const excelAPICaseAIExtractor = async (token, transactionId, status, path, sheet) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_PO_FILE_BY_TID_AI_EXTRACTOR}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'transaction_id': transactionId,
            'file_path': path,
            'excel_as_html': true,
            'sheet_name': sheet
        })
    })
        .then(res => {
            return res.arrayBuffer()
        })
        .then(element => {
            const decoder = new TextDecoder("utf-8")
            return decoder.decode(element);
        })

        .catch(err => {
            console.error(err);
            return { detail: err }
        });
    return returnCall;
}

const PDFAPICaseAIExtractor = async (token, transactionId, status, path) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_PO_FILE_BY_TID_AI_EXTRACTOR}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'transaction_id': transactionId,
            'status': status,
            'file_path': path
        })
    })
        .then(response => {
            return response.blob()
        })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getAttatchmentsDataAIExtractor = async (token, transactionId, status, path, isExcel, sheet = "") => {
    return isExcel
        ? await excelAPICaseAIExtractor(token, transactionId, status, path, sheet)
        : await PDFAPICaseAIExtractor(token, transactionId, status, path)
}

export const getEmailAditionalDataAPIAIExtractor = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_EMAIL_ADDITIONAL_DATA_AI_EXTRACTOR}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const approveByTidIndexAIExtractor = async (transactionId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_APPROVE_TID_INDEX_AI_EXTRACTOR}?${new URLSearchParams({
        'transaction_id': transactionId,
        'index': index
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const unapproveByTidIndexAIExtractor = async (transactionId, index, token) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_UNDO_APPROVE_TID_AI_EXTRACTOR}?${new URLSearchParams({
        'transaction_id': transactionId,
        'index': index
    })}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const editCurrentJSONAIExtractor = async (transactionId, JSONData, modifiedRows, currentIndex, token, isMassApproval = false) => {
    const returnCall = await fetch(API_URLS.FREETIER_UPDATE_PO_JSON_BY_INDEX_AI_EXTRACTOR, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'final_json': JSONData,
            'transaction_id': transactionId,
            'index': currentIndex,
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const updateJSONAIExtractor = async (token, JSONData, transactionId, index) => {
    const returnCall = await fetch(API_URLS.FREETIER_UPDATE_PO_JSON_BY_INDEX_AI_EXTRACTOR, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'final_json': JSONData,
            'transaction_id': transactionId,
            'index': index
        })
    })
        .then(response => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getEmailAPIAIExtractor = async (token, transactionId) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_HTML_BODY_EMAIL_AI_EXTRACTOR}?${new URLSearchParams({
        'transaction_id': transactionId,
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const auditsFiltersAIExtractor = async (
    token, transactionId = "ALL", workflowId = "ALL", userId = "ALL",
    username = "ALL", clientId = "ALL", action = "ALL", prevValue = "ALL", currentValue = "ALL", start_order_date = "ALL", end_order_date = "ALL", mailbox = "ALL", page, size
) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_AUDITS_FILTERS_AI_EXTRACTOR_URL}?${new URLSearchParams({
        'transactionId': transactionId,
        'workflowId': workflowId,
        'userid': userId,
        'username': username,
        'clientId': clientId,
        'action': action,
        'prevValue': prevValue,
        'currentValue': currentValue,
        'start_order_date': start_order_date,
        'end_order_date': end_order_date,
        'mailboxes': mailbox,
        'page': page,
        'size': size
    })}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => response.json()
            .then(data => ({ status: response.status, data }))
        )

        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return { detail: error }
        })
    return returnCall;
}

export const getMailboxesData = async (token, mailboxes, regions) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_GET_MAILBOXES_DATA}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            mailboxes: mailboxes,
            regions: regions
        }),
    })
        .then(response => {
            if (response.status === 200) {
                const data = response.json()
                    .then((ArrayResponse) => {
                        return { status: "SUCCESS", payload: ArrayResponse }
                    });
                return data;
            } else {
                return { status: "ERROR" };
            }
        })
    return returnCall;
}

export const testTransactionOrders = async (token, transactions = []) => {
    const returnCall = await fetch(`${API_URLS.FREETIER_TEST_TRANSACTIONS_ORDERS}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            transactions
        }),
    })
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.log(error);
            return { detail: error }
        })
    return returnCall;
}

