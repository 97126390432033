import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Button, Card, CardContent,
    Grid, Typography
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';
import { STORAGE_KEYS } from '../../utils/utils';
import { getCheckToken } from '../../APICalls';
import handleLogout from '../../utils/handleLogout';

const ButtonStyles = { height: '35px', alignItems: 'center', display: 'flex', justifyContent: 'center' };

const PAGES_CARDS = [
    {
        "title": <b>Sales Order <br /> Processing</b>,
        "key": "sop",
        "redirect": "/SOPHome",
        "logic": "ALL",
        "description": "Simplify sales order processing with AI-driven automation.",
        "imageurl": "https://vao-open-bucket.s3.us-east-1.amazonaws.com/landing-page/SOM-dashboard.png"

    },
    {
        "title": <b>Order Status <br /> Tracking</b>,
        "key": "order_tracking",
        "redirect": "/orderTracking",
        "logic": "VAO",
        "description": "Track and manage procurement orders with real-time AI insights.",
        "imageurl": "https://vao-open-bucket.s3.us-east-1.amazonaws.com/landing-page/PST-dashboard.png"
    },
    {
        "title": <b>Invoice <br /> Management</b>,
        "key": "invoice_management",
        "redirect": "/invoiceManagement",
        "logic": "VAO",
        "description": "Automate vendor invoice workflows for faster, accurate processing.",
        "imageurl": "https://vao-open-bucket.s3.us-east-1.amazonaws.com/landing-page/VIM-dashboard.png"
    },
    {
        "title": <b>Intelligent <br /> Document Processing</b>,
        "key": "ai_extractor",
        "redirect": "/AI-Extractor-Dashboard",
        "logic": "VAO",
        "description": "AI-powered document handling for seamless integration of business systems.",
        "imageurl": "https://vao-open-bucket.s3.us-east-1.amazonaws.com/landing-page/IDP-dashboard.png"
    }
]

const MiddlePageView = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [userTokenData, setUserTokenData] = useState(null);

    const navigate = useNavigate();

    const validationCallAPI = async () => {
        const { status, data } = await getCheckToken(localStorage.getItem(STORAGE_KEYS.TOKEN));
        if (status === 200) {
            setUserTokenData(data);
        } else {
            handleLogout(() => navigate('/home'))
        }
    }

    const handleNavigate = (navigationURL) => () => {
        navigate(navigationURL)
    }

    const filteredData = () => {
        const isVao = userTokenData?.username?.includes("@vao.world");
        if (isVao) {
            return PAGES_CARDS
        } else {
            const dataKeys = Object.keys(userTokenData?.permissions || {})
                .filter(
                    key => userTokenData?.permissions?.[key]
                ) || [];
            if (dataKeys) {
                return PAGES_CARDS.filter(item => dataKeys.includes(item.key))
            } else {
                return PAGES_CARDS.filter((element) => element.logic === "ALL");
            }
        }
    }

    useEffect(() => {
        validationCallAPI();
    }, [])

    return (
        <>
            <Grid
                container
                paddingTop={isSmallScreen ? 5 : 5}
                paddingBottom={isSmallScreen ? 10 : 5}
                alignItems={isSmallScreen ? 'center' : "center"}
                style={{
                    backgroundColor: "white",
                    minHeight: '100vh',
                    fontFamily: 'Montserrat, sans-serif',
                    backgroundAttachment: "fixed"
                }}
                spacing={isSmallScreen ? 2 : 5}
            >
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} padding={isSmallScreen ? 2 : 0}>
                                    <Typography
                                        style={{
                                            fontFamily: 'Montserrat, sans-serif',
                                        }}
                                        variant={isSmallScreen ? "h4" : "h3"}
                                        textAlign="center"
                                        textJustify="inter-word"
                                        color="black"
                                    >
                                        <b>
                                            Welcome to VAO!
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} padding={isSmallScreen ? 2 : 0}>
                                    <Typography
                                        style={{
                                            fontFamily: 'Montserrat, sans-serif',
                                        }}
                                        variant={isSmallScreen ? "h5" : "h4"}
                                        textAlign="center"
                                        textJustify="inter-word"
                                        color="black"
                                    >
                                        Please choose one of our solutions to start.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} padding={3} justifyContent="center" alignItems="center">
                        {userTokenData ? filteredData().map((element) => {
                            return (
                                <Grid item xs={12} md={3}>
                                    <Button
                                        onClick={handleNavigate(element.redirect)}
                                        sx={{
                                            borderRadius: "25px",
                                        }}
                                    >
                                        <Card
                                            style={{
                                                backgroundColor: "#f2f2f2",
                                                color: "#183d44",
                                                minWidth: "300px",
                                                minHeight: isSmallScreen ? "250px" : "420px",
                                                borderRadius: "20px",
                                            }}
                                            sx={{
                                                "&:hover": {
                                                    //backgroundColor: "#94ffff",
                                                    boxShadow: "0px 0px 20px 6px #94ffff",
                                                },
                                            }}
                                        >
                                            <CardContent style={{ padding: 0 }}>
                                                <Grid container
                                                    padding={2}
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12} >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    style={{
                                                                        fontFamily: 'Montserrat, sans-serif',
                                                                    }}
                                                                    variant={isSmallScreen ? "h5" : "h6"}
                                                                    textAlign="center"
                                                                    color="black"
                                                                >
                                                                    <b
                                                                        style={{
                                                                            color: "#015653",
                                                                        }}
                                                                    >
                                                                        {element.title}
                                                                    </b>
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <img
                                                                    src={element.imageurl}
                                                                    style={{
                                                                        maxWidth: isSmallScreen ? "50%" : "80%",
                                                                        height: "auto"
                                                                    }}
                                                                    alt="VAO Logo"
                                                                >
                                                                </img>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    style={{
                                                                        fontFamily: 'Montserrat, sans-serif',
                                                                        textTransform: "none",
                                                                        color: "#015653",
                                                                    }}
                                                                    textAlign="center"
                                                                    color="black"
                                                                >
                                                                    {element.description}
                                                                </Typography>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card >
                                    </Button>
                                </Grid>
                            )
                        }) : <></>}

                    </Grid>
                </Grid>
            </Grid >

        </>
    )
}

export default MiddlePageView;